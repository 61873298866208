<template>
  <div>
    <loader :loading="loading"/>

    <page-header :title="`Treinamentos`">
      <template v-slot:breadcrumbs>
        <li>Treinamentos</li>
      </template>

      <template v-slot:actions>
        <div>
          <button class="btn btn-success" @click="clearFields" data-bs-toggle="modal" data-bs-target="#lesson-modal">Novo Treinamento</button>
        </div>
      </template>
    </page-header>

    <filters @search="handleSearch(filters, 1)" @clear="clearFilters">
      <form @keyup.enter="handleSearch(filters)">
        <div class="row g-2 mt-2">
          <div class="col-4">
            <div>
              <label class="form-label">Título</label>
              <input type="text" class="form-control form-control-sm" v-model="filters.title">
            </div>
          </div>

          <div class="col-3">
            <label class="form-label">Tipo</label>
            <select
                class="form-select form-select-sm"
                @change="filters.category_id = null"
                v-model="filters.type"
            >
              <option value="">Todos</option>
              <option v-for="category in root" :value="category.id">{{category.name}}</option>
            </select>
          </div>

          <div class="col-3">
            <label class="form-label">Categoria</label>
            <select
                class="form-select form-select-sm"
                v-model="filters.category_id"
            >
              <option value="">Todos</option>
              <option v-for="category in filterCategories" :value="category.id">{{category.name}}</option>
            </select>
          </div>

          <div class="col-2">
            <label class="form-label">Situação</label>
            <select
                class="form-select form-select-sm"
                v-model="filters.status"
            >
              <option value="">Todos</option>
              <option value="published">Publicado</option>
              <option value="schedule">Programado</option>
              <option value="draft">Rascunho</option>
            </select>
          </div>
        </div>
      </form>
    </filters>

    <div class="card bg-white mt-4">
      <div class="card-body p-4">
        <div class="d-flex align-items-center justify-content-end">
          <span class="small text-secondary" v-if="collection.data.length">
            Mostrando {{ collection.data.length }} de {{ collection.meta.total }} registros
          </span>
        </div>
        <div class="table-responsive mt-3">

          <table class="table table-striped" v-if="collection.data.length">
            <thead>
            <tr>
              <th class="text-center">Ordem</th>
              <th>Título</th>
              <th class="text-center">Tipo</th>
              <th class="text-center">Categoria</th>
              <th class="text-center">Situação</th>
              <th class="text-center">Tela inicial</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="lesson in collection.data" :key="lesson.id">
              <td class="text-center">{{ lesson.order }}</td>
              <td>{{ lesson.title }}</td>
              <td class="text-center">{{ lesson.category_object.parent.name}}</td>
              <td class="text-center">{{ lesson.category}}</td>
              <td class="text-center">
                {{ getStatus(lesson.status)}}
              </td>
              <td class="text-center">
                {{ lesson.start_screen === 1 ? 'Sim' : 'Não'}}
              </td>
              <td>
                <div class="d-flex align-items-center justify-content-end">
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary btn-sm dropdown-toggle"
                            data-bs-popper-config='{"strategy":"fixed"}' data-bs-toggle="dropdown">
                      Ações
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#" @click.prevent="edit(lesson.id)">Editar</a></li>
                      <li><a class="dropdown-item" href="#" @click.prevent="remove(lesson.id)">Remover</a></li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="bg-light p-2 rounded-2 text-center" v-else>Nenhum registro encontrado...</div>

          <div class="d-flex align-items-center justify-content-center mt-4">
            <paginator
                :current-page="collection.meta.current_page"
                :limit-pages="5"
                :pages="collection.meta.last_page"
                @page-changed="handleSearch(filters, $event)"
                v-if="collection.data.length"
            />
          </div>
        </div>
      </div>
    </div>

    <modal id="lesson-modal" :custom-classes="['modal-dialog-centered', 'modal-lg']" title="Treinamento">
      <template #body>
        <div class="row g-2">
          <div class="col-6">
            <label class="form-label">Tipo</label>
            <select
                class="form-select"
                @change="payload.category_id = null"
                v-model="$v.payload.type.$model"
                :class="{ 'is-invalid' : $v.payload.type.$error }"
            >
              <option v-for="category in root" :value="category.id">{{category.name}}</option>
            </select>
          </div>

          <div class="col-6">
            <label class="form-label">Categoria</label>
            <select
                class="form-select"
                v-model="$v.payload.category_id.$model"
                :class="{ 'is-invalid' : $v.payload.category_id.$error }"
            >
              <option v-for="category in categories" :value="category.id">{{category.name}}</option>
            </select>
          </div>

          <div class="col-3">
            <label class="form-label">Situação</label>
            <select
                class="form-select"
                v-model="$v.payload.status.$model"
                :class="{ 'is-invalid' : $v.payload.status.$error }"
                @change="onChangeStatus"
            >
              <option value="published">Publicado</option>
              <option value="schedule">Programado</option>
              <option value="draft">Rascunho</option>
            </select>
          </div>

          <div class="col-2" v-if="payload.status !== 'schedule'">
            <label class="form-label">Tela inicial?</label>
            <select
                class="form-select"
                v-model="payload.start_screen"
                @change="payload.start_date = null; payload.end_date = null;"
            >
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </select>
          </div>

          <div class="col-5" v-if="payload.status !== 'schedule'">
            <label class="form-label">Período de programação</label>
            <div class="d-flex align-items-center gap-2">
              <date-picker
                  type="date"
                  format="DD/MM/YYYY"
                  value-type="YYYY-MM-DD"
                  :disabled="payload.start_screen == 0"
                  v-model="$v.payload.start_date.$model"
                  :input-class="$v.payload.start_date.$error ? 'form-control is-invalid' : 'form-control'"
              />

              <date-picker
                  type="date"
                  format="DD/MM/YYYY"
                  value-type="YYYY-MM-DD"
                  :disabled="payload.start_screen == 0"
                  v-model="$v.payload.end_date.$model"
                  :input-class="$v.payload.end_date.$error ? 'form-control is-invalid' : 'form-control'"
              />
            </div>
          </div>

          <div class="col-4" v-if="payload.status === 'schedule'">
            <label class="form-label">Data Programada</label>
            <date-picker
                v-model="$v.payload.due_date.$model"
                type="datetime"
                :input-class="$v.payload.due_date.$error ? 'form-control is-invalid' : 'form-control'"
                format="DD/MM/YYYY HH:mm"
                value-type="YYYY-MM-DD HH:mm:ss"
                :disabled="payload.status !== 'schedule'"
            />
          </div>

          <div class="col-3" v-if="payload.status === 'schedule'">
            <label class="form-label">Senha</label>
            <input
                class="form-control"
                v-model="payload.password"
                :disabled="payload.status !== 'schedule'"
            />
          </div>

          <div class="col-2">
            <label class="form-label">Ordem</label>
            <input
                type="number"
                step="1"
                min="0"
                class="form-control"
                v-model="$v.payload.order.$model"
                :class="{ 'is-invalid' : $v.payload.order.$error }"
            />
          </div>

          <div class="col-12">
            <label class="form-label">Título</label>
            <input
                class="form-control"
                v-model="$v.payload.title.$model"
                :class="{ 'is-invalid' : $v.payload.title.$error }"
            />
          </div>

          <div class="col-8">
            <label class="form-label">Descrição</label>
            <textarea
                class="form-control"
                style="height: 150px"
                v-model="$v.payload.content.$model"
                :class="{ 'is-invalid' : $v.payload.content.$error }"
            >

            </textarea>
          </div>

          <div class="col-4">
            <div>
              <label class="form-label">Imagem de capa</label>
              <input
                  type="file"
                  class="form-control form-control-sm d-none"
                  accept="image/*"
                  ref="file"
                  @change="onFileChange"
              >
            </div>
            <div
                class="border border-2 border-primary rounded-3 cursor-pointer"
                :class="{'p-2' : preview}"
                @click.prevent="$refs.file.click()"
            >
              <img
                  :src="preview ? preview : `https://via.placeholder.com/243x148/f8f9fa/6769f0/?text=Capa`"
                  class="rounded-3 border-info cursor-pointer"
                  width="100%"
              />
            </div>
          </div>

          <div class="col-12">
            <label class="form-label">Url do Vídeo</label>
            <input
                class="form-control"
                v-model="$v.payload.media_url.$model"
                :class="{ 'is-invalid' : $v.payload.media_url.$error }"
            />
          </div>
        </div>
      </template>

      <template #footer>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" @click.prevent="handleSave">Salvar</button>
      </template>
    </modal>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import PageHeader from "@/components/PageHeader.vue";
import Paginator from "@/components/shared/paginator.vue";
import Loader from "@/components/shared/loader.vue";
import Filters from "@/components/shared/Filters.vue";
import axios from "axios";
import Modal from "@/components/shared/Modal.vue";
import {Modal as bsModal} from "bootstrap";
import {required, requiredIf, url} from "vuelidate/lib/validators";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import 'vue2-datepicker/locale/pt-br';

export default {
  name: 'lessons',
  props: {},
  components: {
    Modal,
    Filters,
    Loader,
    Paginator,
    PageHeader,
    DatePicker
  },
  data() {
    return {
      loading: false,
      filters: {
        title: null,
        type: '',
        category_id: '',
        status: ''
      },
      modal: null,
      preview: null,
      payload: {
        start_screen: 0,
        start_date: null,
        end_date: null,
        type: null,
        category_id: null,
        title: null,
        content: null,
        media_url: null,
        thumbnail: null,
        status: 'draft',
        order: 1,
        due_date: null,
        password: null
      }
    }
  },
  validations() {
    return {
      payload: {
        type: {required},
        category_id: {required},
        title: {required},
        content: {required},
        media_url: {required, url},
        status: {required},
        order: { required },
        due_date: {
          required: requiredIf(function() {
            return this.payload.status === 'schedule';
          })
        },
        start_date: {
          required: requiredIf(function() {
            return this.payload.start_screen === '1';
          })
        },
        end_date: {
          required: requiredIf(function() {
            return this.payload.start_screen === '1';
          })
        }
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.getRoot();
    await this.handleSearch(this.filters, this.$route.query.page ?? 1);
    this.modal = bsModal.getOrCreateInstance(document.getElementById('lesson-modal'));
  },
  methods: {
    ...mapActions('Lessons', ['getAll']),
    ...mapActions('LessonsCategories', ['getRoot']),
    getStatus(status) {
     const data = {
       published: 'Publicado',
       draft: 'Rascunho',
       schedule: 'Programado'
     }
     return data[status];
    },
    clearFields() {
      this.payload =  {
        type: null,
        category_id: null,
        title: null,
        content: null,
        media_url: null,
        thumbnail: null,
        status: 'draft',
        order: 1,
        due_date: null,
        start_screen: 0,
        start_date: null,
        end_date: null,
      }
      this.preview = null;
      this.$v.$reset();
    },
    onFileChange(e) {
      this.payload.thumbnail = null;
      this.preview = null;
      const file = e.target.files[0];
      this.preview = URL.createObjectURL(file);
      this.payload.thumbnail = file;
      this.$forceUpdate();
    },
    async handleSearch(filters, page = 1) {
      this.loading = true;
      const params = {
        page: page,
        ...filters
      }

      await this.getAll(params).finally(() => {
        this.loading = false;
        this.$scrollTop();
      })

      this.$urlUtils.assignQueryString(this.$route, params);
    },
    clearFilters() {
      this.filters = {
        title: null,
        type: '',
        category_id: '',
        status: ''
      }

      this.handleSearch(this.filters, 1);
    },
    async edit(id) {
      this.loading = true;
      await axios.get(`/admin/lessons/${id}`).then(response => {
        const { data } = response;
        this.payload.id = data.id;
        this.payload.type = data.category.parent.id;
        this.payload.category_id = data.lesson_category_id;
        this.payload.title = data.title;
        this.payload.content = data.content;
        this.payload.media_url = data.media_url;
        this.payload.status = data.status;
        this.payload.order = data.order;
        this.payload.due_date = data.due_date;
        this.preview = data.thumbnail_url;
        this.payload.password = data.password;
        this.payload.start_screen = data.start_screen === 0 ? '0' : '1';
        this.payload.start_date = data.start_date;
        this.payload.end_date = data.end_date,

        this.modal.show();

      }).finally(() => {
        this.loading = false;
      })
    },
    async handleSave() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return false
      }

      this.loading = true;
      const data = this.$serialize(this.payload);

      let url = '/admin/lessons'
      if(this.payload.id) {
        url = `/admin/lessons/${this.payload.id}`;
        data.append('_method', 'PUT');
      }

      await axios.post(url, data).then(() => {
        this.clearFilters();
        this.modal.hide();
        this.clearFields();
      }).finally(() => {
        this.loading = false;
      })
    },
    async remove(id) {
      await this.$swal.fire({
        text: 'Confirma a exclusão do registro selecionado?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: `Não`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          await axios.delete(`/admin/lessons/${id}`).then(() => {
            this.clearFilters()
          }).catch(() => {
            this.$swal({
              icon: 'error',
              text: 'Ocorreu um erro ao excluir esse treinamento'
            })
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
    async onChangeStatus() {
      this.payload.due_date = null;
      this.payload.password = null;

      await this.$nextTick();

      if(this.payload.status === 'schedule') {
        this.payload.start_date = null;
        this.payload.end_date = null;
        this.payload.start_screen = 0;
      }
    }
  },
  computed: {
    ...mapState('Lessons', ['collection']),
    ...mapState('LessonsCategories', ['root']),
    categories() {
      if(!this.payload.type) {
        return [];
      }

      return this.root.find(item => item.id === this.payload.type).categories
    },
    filterCategories() {
      if(!this.filters.type) {
        return [];
      }

      return this.root.find(item => item.id === this.filters.type).categories
    }
  }
}
</script>
<style lang="scss" scoped>
.mx-datepicker {
  width: initial !important;
}
</style>
